
.collapseBlock
  h4
    @apply cursor-pointer
  &.open
    .collapseBlock__body
      max-height: 100%
    .owl-caret-right
      @apply inline-block
      transform: rotate(90deg)
  &__body
    max-height: 8rem
    @apply overflow-hidden relative
    &__mask
      @apply block w-full absolute cursor-pointer
      bottom: 0
      content: ''
      height: 8rem
      background: linear-gradient(to bottom, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%)
