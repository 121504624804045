
.payment-callback__qrcode-template
  margin-bottom: 150px

  .qr-code
    width: 200px
    height: 200px
    // @apply border-1 border-grey

  .btn-line
    background-color: #00c43e
